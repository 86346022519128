export default [

  // *===============================================---*
  // *--------- SUPPLIER -------------------------------*
  // *===============================================---*
  {
    path: '/supplier/list',
    name: 'supplier-list',
    component: () => import('@/views/manage/supplier/list/SupplierList.vue'),
    meta: {
      resource: 'supplier',
      action: 'read',
    },
  },
  {
    path: '/supplier/new',
    name: 'supplier-new',
    component: () => import('@/views/manage/supplier/new-edit/NewSupplier.vue'),
    meta: {
      resource: 'supplier',
      action: 'create',
    },
  },
  {
    path: '/supplier/edit/:id',
    name: 'supplier-edit',
    component: () => import('@/views/manage/supplier/new-edit/EditSupplier.vue'),
    meta: {
      resource: 'supplier',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- CASHBOOK -------------------------------*
  // *===============================================---*
  {
    path: '/cashbook/list',
    name: 'cashbook-list',
    component: () => import('@/views/manage/cashbook/list/CashbookList.vue'),
    meta: {
      resource: 'cash_book',
      action: 'read',
    },
  },
  {
    path: '/cashbook/new',
    name: 'cashbook-new',
    component: () => import('@/views/manage/cashbook/new-edit/NewCashbook.vue'),
    meta: {
      resource: 'cash_book',
      action: 'create',
    },
  },
  {
    path: '/cashbook/edit/:id',
    name: 'cashbook-edit',
    component: () => import('@/views/manage/cashbook/new-edit/EditCashbook.vue'),
    meta: {
      resource: 'cash_book',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- PURCHASE -------------------------------*
  // *===============================================---*
  {
    path: '/purchase/list',
    name: 'purchase-list',
    component: () => import('@/views/manage/purchase/list/PurchaseList.vue'),
    meta: {
      resource: 'purchase',
      action: 'read',
    },
  },
  {
    path: '/purchase/new',
    name: 'purchase-new',
    component: () => import('@/views/manage/purchase/new-edit/NewPurchase.vue'),
    meta: {
      resource: 'purchase',
      action: 'create',
    },
  },
  {
    path: '/purchase/edit/:id',
    name: 'purchase-edit',
    component: () => import('@/views/manage/purchase/new-edit/EditPurchase.vue'),
    meta: {
      resource: 'purchase',
      action: 'update',
    },
  },
  {
    path: '/purchase/view/:id',
    name: 'purchase-view',
    component: () => import('@/views/manage/purchase/list/ViewPurchase.vue'),
    meta: {
      resource: 'purchase',
      action: 'read',
    },
  },

]
