export default [

  // *===============================================---*
  // *--------- INVENTORY ------------------------------*
  // *===============================================---*
  {
    path: '/inventory/list',
    name: 'inventory-list',
    component: () => import('@/views/store/inventory/list/InventoryList.vue'),
    meta: {
      resource: 'inventory',
      action: 'read',
    },
  },
  {
    path: '/inventory/new',
    name: 'inventory-new',
    component: () => import('@/views/store/inventory/new-edit/NewInventory.vue'),
    meta: {
      resource: 'inventory',
      action: 'create',
    },
  },
  {
    path: '/inventory/edit/:id',
    name: 'inventory-edit',
    component: () => import('@/views/store/inventory/new-edit/EditInventory.vue'),
    meta: {
      resource: 'inventory',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- RECEIPT --------------------------------*
  // *===============================================---*
  {
    path: '/receipt/list',
    name: 'receipt-list',
    component: () => import('@/views/store/receipt/list/ReceiptList.vue'),
    meta: {
      resource: 'receipt',
      action: 'read',
    },
  },
  {
    path: '/receipt/new',
    name: 'receipt-new',
    component: () => import('@/views/store/receipt/new-edit/NewReceipt.vue'),
    meta: {
      resource: 'receipt',
      action: 'create',
    },
  },
  {
    path: '/receipt/edit/:id',
    name: 'receipt-edit',
    component: () => import('@/views/store/receipt/new-edit/EditReceipt.vue'),
    meta: {
      resource: 'receipt',
      action: 'update',
    },
  },
  {
    path: '/receipt/view/:id',
    name: 'receipt-view',
    component: () => import('@/views/store/receipt/list/ViewReceipt.vue'),
    meta: {
      resource: 'receipt',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- ISSUE ----------------------------------*
  // *===============================================---*
  {
    path: '/issue/list',
    name: 'issue-list',
    component: () => import('@/views/store/issue/list/IssueList.vue'),
    meta: {
      resource: 'issue',
      action: 'read',
    },
  },
  {
    path: '/issue/new',
    name: 'issue-new',
    component: () => import('@/views/store/issue/new-edit/NewIssue.vue'),
    meta: {
      resource: 'issue',
      action: 'create',
    },
  },
  {
    path: '/issue/edit/:id',
    name: 'issue-edit',
    component: () => import('@/views/store/issue/new-edit/EditIssue.vue'),
    meta: {
      resource: 'issue',
      action: 'update',
    },
  },
  {
    path: '/issue/view/:id',
    name: 'issue-view',
    component: () => import('@/views/store/issue/list/ViewIssue.vue'),
    meta: {
      resource: 'issue',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- INVENTORY ADJUSTMENT -------------------*
  // *===============================================---*
  {
    path: '/adjustment/list',
    name: 'adjustment-list',
    component: () => import('@/views/store/adjustment/list/AdjustmentList.vue'),
    meta: {
      resource: 'adjustment',
      action: 'read',
    },
  },
  {
    path: '/adjustment/new',
    name: 'adjustment-new',
    component: () => import('@/views/store/adjustment/new-edit/NewAdjustment.vue'),
    meta: {
      resource: 'adjustment',
      action: 'create',
    },
  },
  {
    path: '/adjustment/edit/:id',
    name: 'adjustment-edit',
    component: () => import('@/views/store/adjustment/new-edit/EditAdjustment.vue'),
    meta: {
      resource: 'adjustment',
      action: 'update',
    },
  },
  {
    path: '/adjustment/view/:id',
    name: 'adjustment-view',
    component: () => import('@/views/store/adjustment/list/ViewAdjustment.vue'),
    meta: {
      resource: 'adjustment',
      action: 'read',
    },
  },

]
