export default [

  // *===============================================---*
  // *--------- SALE REVENUE ---------------------------*
  // *===============================================---*
  {
    path: '/report/sale-revenue',
    name: 'report-sale-revenue',
    component: () => import('@/views/report/sale-revenue/Revenue.vue'),
    meta: {
      resource: 'report_sale_revenue',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- SALE ITEM ---------------------------*
  // *===============================================---*
  {
    path: '/report/sale-item',
    name: 'report-sale-item',
    component: () => import('@/views/report/sale-item/SaleItem.vue'),
    meta: {
      resource: 'report_sale_item',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- INVENTORY ITEM -------------------------*
  // *===============================================---*
  {
    path: '/report/inventory-item',
    name: 'report-inventory-item',
    component: () => import('@/views/report/inventory-item/InventoryItem.vue'),
    meta: {
      resource: 'report_inventory_item',
      action: 'read',
    },
  },

]
